<template>
  <!-- Informations générales communes à toutes les pages -->
  <div class="general-information-subview">
    <template v-if="isEditable">
      <teleport to="#header-action">
        <Btn
          v-if="helperService.userHasPermission('essaievenement_oedit')"
          class="action-btn"
          icon="create"
          color="white"
          hollow
          text="Modifier"
          :to="{
          name: 'essaiExperimentalTaskGeneralInformationEdit',
          params: {
            id: $route.params.id,
            tid: $route.params.tid
          }
        }"
        />
      </teleport>
    </template>
  </div>

  <div class="tabs-material-subtitle">
    <Container>
      <h2>Informations générales</h2>
    </Container>
  </div>

  <div class="key-values key-values--page-bottom">
    <KeyValue
      table
      label="Type"
      :value="task?.type?.designation"
    />
    <KeyValue
      table
      label="Désignation"
      :value="task?.designation"
    />
    <KeyValue
      table
      label="Stade prévisionnel"
      :value="task?.stade?.designation"
    />
    <KeyValue
      table
      label="Date de réalisation"
      :value="helperService.displayDate(task?.date_realisation)"
    />
    <KeyValue
      table
      label="Date prévisionnelle"
      :value="helperService.displayDate(task?.date_previsionnelle)"
    />
    <KeyValue table label="Observation" :value="task?.observations"/>
  </div>
</template>

<script>

import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'GeneralInformationSubview',
  components: {
    Container,
    Btn,
    KeyValue,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {}
  },

  emits: ['set-editable'],

  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
</style>
